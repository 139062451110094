.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--container-color);
}
.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}
.nav_logo,
.nav_toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}
.nav_list {
  display: flex;
  column-gap: 2rem;
}
.nav_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}
.nav_icon,
.nav_close,
.nav_toggle {
  display: none;
}

/* Active link */
.active-link,
.nav_link:hover {
  color: var(--text-color);
}
/* Change Background Header */
.scroll-header {
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

/* light and dark mode switcher */
.theme-checkbox {
  --toggle-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3rem;
  height: 1.2rem;
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(50%, #efefef),
      color-stop(50%, #2a2a2a)
    )
    no-repeat;
  background: -o-linear-gradient(left, #efefef 50%, #2a2a2a 50%) no-repeat;
  background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
  background-size: 205%;
  background-position: 0;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-radius: 99em;
  position: relative;
  cursor: pointer;
  font-size: var(--toggle-size);
}

.theme-checkbox::before {
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.1em;
  left: 1.8em;
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(50%, #efefef),
      color-stop(50%, #2a2a2a)
    )
    no-repeat;
  background: -o-linear-gradient(left, #efefef 50%, #2a2a2a 50%) no-repeat;
  background: linear-gradient(to right, #efefef 50%, #2a2a2a 50%) no-repeat;
  background-size: 205%;
  background-position: 100%;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.theme-checkbox:checked::before {
  left: calc(100% - 2.25em - 0.438em);
  background-position: 0;
}

.theme-checkbox:checked {
  background-position: 100%;
}
/* ==== BREAKPOINTS ==== */
@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
  }
  .nav {
    height: var(--header-height);
  }
  .nav_menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
  }
  /* Show Menu */
  .show-menu {
    bottom: 0;
  }
  .nav_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
  .nav_icon {
    font-size: 1.2rem;
  }
  .nav_close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }
  .nav_close:hover {
    color: var(--title-color-dark);
  }
  .nav_toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }
  .nav_icon,
  .nav_close,
  .nav_toggle {
    display: block;
  }
}
/* For small devices */
@media screen and (max-width: 350px) {
  .container {
    margin-left: 10px;
    margin-right: 10px;
  }
  .nav_menu {
    padding: 2rem 0.25rem 4rem;
  }
}
