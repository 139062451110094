.testimonial_container {
  width: 100%; /* Ensure the container uses full width */
  max-width: 1200px; /* Max width for large screens */
  margin: 0 auto; /* Center align the container */
}
.testimonial_card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  margin-bottom: var(--mb-3);
}

.testimonial_name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-25);
}
.testimonial_description {
  font-size: var(--small-font-size);
}

/* Swiper Class */
.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--title-color) !important;
}

/* ==== BREAKPOINTS ==== */
/* For large devices */
@media screen and (max-width: 992px) {
  .testimonial_container {
    width: initial;
  }
  .testimonial_card {
    padding: 1.25rem 1.5rem;
  }
}
