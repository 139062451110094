.about_container {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 4rem;
}
.about_img {
  width: 350px;
  border-radius: 1.5rem;
  justify-self: center;
}
.about_info {
  grid-template-columns: repeat(3, 140px);
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}
.about_box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}
.about_icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}
.about_title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.about_subtitle {
  font-size: var(--tiny-font-size);
}
.about_description {
  padding: 0 4rem 0 0;
  margin-bottom: var(--mb-2-5);
}

/* ==== BREAKPOINTS ==== */
/* For large devices */
@media screen and (max-width: 992px) {
  .about_container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }
  .about_img {
    width: 220px;
    position: relative;
    left: 38%;
  }
  .about_box {
    padding: 0.75rem 0.5rem;
  }
  .about_data {
    text-align: center;
  }
  .about_info {
    justify-content: center;
  }
  .about_description {
    padding: 0 5rem;
    margin-bottom: 2rem;
  }
}
/* For medium devices */
@media screen and (max-width: 768px) {
  .about_img {
    position: relative;
    left: 35%;
  }
}
@media screen and (max-width: 576px) {
  .about_info {
    grid-template-columns: repeat(3, 1fr);
  }
  .about_description {
    padding: 0;
  }
  .about_img {
      position: relative;
      left: 28%;
    }
}
/* For small devices */
@media screen and (max-width: 430px) {
  .about_img {
      position: relative;
      left: 20%;
    }
}
@media screen and (max-width: 350px) {
  .about_info {
    grid-template-columns: repeat(2, 1fr);
  }
  .about_img {
      position: relative;
      left: 15%;
    }
}
